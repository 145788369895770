/* ==========================================================================
   1. IMPORT GOOGLE FONTS
   ========================================================================== */

@font-face {
  font-family: 'Gordita';
  src: url('../src/website/assets/fonts/GorditaLight.woff2');
  font-weight: 100;
}

@font-face {
  font-family: 'Gordita';
  src: url('../src/website/assets/fonts/GorditaRegular.woff2');
  font-weight: 300;
}

@font-face {
  font-family: 'Gordita';
  src: url('../src/website/assets/fonts/GorditaMedium.woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'Gordita';
  src: url('../src/website/assets/fonts/GorditaBold.woff2');
  font-weight: 700;
}

/* ==========================================================================
      2. GENERAL
      ========================================================================== */

body {
  font-family: "Gordita", sans-serif !important;
  color: #000;
  font-weight: 300;
  font-size: 15px;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4 {
  word-break: break-word;
}

p {
  line-height: 30px;
  color: #000;
  font-size: 15px;
}

a {
  line-height: 35px;
  color: #000;
}

a:hover, a:focus {
  color: #333;
  text-decoration: none;
  opacity: 0.95;
}

#app-section {
  overflow: hidden;
}

h5 {
  outline: none;
}

h4 {
  display: block;
  font-weight: 300;
  line-height: normal !important;
}

.hidden {
  display: none;
}

.menu {
  background-color: #333;
}

.navbar-brand {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: -10px;
}

.input {
  font-size: 0.8em;
  min-height: 50px;
  width: 100%;
  background-color: #ededed;
  padding: 8px 10px;
  outline: none;
  margin: 10px 0px;
  border: none;
  color: grey;
  resize: none
}

.react-date-picker__wrapper {
  border: none;
}

.rounded {
  border-radius: 20px !important;
}

.shadow {
  box-shadow: 0px 0px 13px 1px #ccc;
}

.fshadow {
  border-radius: 5px;
  box-shadow: 0px 0px 40px 20px #cccccc36;
}

.bshadow {
  box-shadow: -2px -4px 10px 0px #ccc;
}

.nav-links a {
  color: #000 !important;
  font-weight: nnormal;
}

span[color="white"] p {
  color: #ffffff;
}

.gridPlain {
  border-radius: 20px;
  box-shadow: 0px -1px 20px 0px #00000030;
  padding: 25px;
}

#textGrid p {
  font-size: 16px;
  line-height: 28px;
}

#textGrid {
  word-break: break-word;
}

.videoHolder {
  position: relative;
  left: -50px;
}

.videoHolder:after {
  width: 50%;
  height: 50%;
  content: url('../src/overlay.svg');
  position: absolute;
  z-index: 20;
  top: 0;
}

.videoPlayer {
  position: relative;
}

.videoPlayer:after {
  width: 100%;
  height: 100%;
  content: url('../src/overlay_video.svg');
  position: absolute;
  z-index: 320;
  right: 0;
  bottom: 0; 
}

#textGrid img, #textGrid svg {
  text-align: center;
  margin: auto;
}

.navbar-toggler {
  outline: none !important;
  border: none;
}

.row {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

@media screen and (min-width: 500px) {
  .rm-p {
    padding-right: 0px !important;
    padding-left: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}

.rm-pv {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.rm-m {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.br {
  padding-right: 50px;
  padding-bottom: 10px;
}

.media .modal-header, .media .modal-body, .media .modal-content {
  border: none;
  background-color: transparent !important;
}

.close {
  color: #fff;
  text-shadow: none;
  opacity: 1;
}

@media (min-width: 576px) {
  .media .modal-dialog {
    max-width: 800px;
  }
}

@media screen and (min-width: 600px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 10px 20px;
  }
  #cookie-consent {
    box-shadow: none !important;
    width: 60%;
  }
}

#cookie-consent span {
  font-size: 15px !important;
}

#cookie-consent-button {
  background-color: #000 !important;
  color: #fff !important;
  height: 16px;
  line-height: 2px !important;
  font-size: 14px !important;
  font-weight: 500;
  margin: 20px;
  padding: 17px 20px !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  /* white-space: nowrap; */
  cursor: pointer;
}

.banner {
  margin: 0px !important;
  position: relative;
  background-color: #e4e4e4 !important;
  font-weight: 300 !important;
  padding: 4px 10px !important;
  font-size: 15px !important;
}

.banner a {
  font-weight: 500;
}

.mr-nav {
  margin-right: 60px;
}

.m-auto {
  margin: auto !important;
}

.overflow {
  overflow: hidden !important;
}

.overflowV {
  overflow: visible;
}

.bold {
  font-weight: 400;
}

.bgWhite {
  background-color: white;
}

.line {
  border-bottom: solid 1px #f5f5f5;
}

.center {
  align-self: center;
  margin: auto;
}

.left {
  align-self: flex-start;
}

#footer span {
  text-transform: capitalize;
}

#footer {
  background-color: #f3f3f3;
}

.Collapsible {
  margin-bottom: 20px;
  box-shadow: 0px 0px 20px 4px #e4e4e4;
  border-radius: 20px;
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 12px;
}

.accordion:nth-child(1) {
  margin-top: 50px;
}

.accordion:last {
  margin-bottom: 50px;
}

.Collapsible span {
  word-wrap: break-word;
  white-space: pre-wrap;
}

ul {
  list-style: none;
  padding-left: 0px !important;
}

ul li {
  padding-bottom: 10px;
  list-style: none;
}

ol li {
  padding-bottom: 10px;
}

@media (max-width: 1000px) {
  .nav-link {
    padding: 20px !important;
    font-size: 1.2em;
    border-bottom: solid 1px #f1ecec;
  }
  .nav-link:last-child {
    padding: 20px !important;
    border-bottom: none;
  }
  #responsive-navbar-nav {
    margin-top: 50px !important;
  }
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  border-bottom: solid 1px #333;
  font-weight: 500;
  border-right: none !important;
  border-left: none !important;
  border-top: none !important;
}

.nav-tabs .nav-item.show .nav-link:hover, .nav-item:hover {
  border: none !important;
}

.nav-tabs {
  justify-content: center;
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.nav-tabs .nav-link {
  margin-right: 0px;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs {
  border: none !important;
  font-size: 1.2em;
  padding-inline: 30px;
  border: none;
}

#card {
  box-shadow: 2px 2px 50px rgba(0, 0, 0, 0.2);
  perspective: 60px;
  border-radius: 30px;
}

#inner {
  transition: transform 0.5s;
  -webkit-transition: transform 0.5s;
}

#inner p, #inner h1 {
  align-items: center;
}

#inner p {
  overflow: hidden;
  text-overflow: ellipsis !important;
  display: -webkit-box;
  -webkit-line-clamp: 5 !important;
  -webkit-box-orient: vertical !important;
}

#card:hover .demo-overlay {
  opacity: 1;
}

#card:hover {
  cursor: pointer;
}

.middleBg h1:after {
  content: "";
  display: block;
  width: 80px;
  height: 2px;
  background: #fff;
  margin-top: 10px;
}

.imageBg {
  opacity: 1;
  display: block;
  z-index: 0;
  transition: .5s ease;
  backface-visibility: hidden;
}

.middleBg {
  transition: .5s ease;
  opacity: 0;
  z-index: 20;
  position: absolute;
}

.containhover:hover video {
  transition: .9s ease;
  transform: scale(1.1) !important;
}

.containhover {
  z-index: 10;
}

.containhover:hover .middleBg {
  cursor: pointer;
  opacity: 1;
}

.containhover:hover .videoPlayer {
  opacity: 0;
}

/* .videoPlayer:hover {
  transition: .5s ease;
  opacity: 0;
  z-index: 20;
  position: absolute;
} */

/* .videoPlayer:hover {
  transition: .5s ease;
  opacity: 0;
  z-index: 20;
  position: absolute;
} */